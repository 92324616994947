import React, {MouseEvent} from "react";
import  "../PrePayment.scss";
import {SubmitButton} from "../../../../../input/component/submit_button/SubmitButton";
import {EventRegistry} from "../../../../../app/event-aware/EventRegistry";
import {EventTypeHolder, EventUtil} from "../../../../../util/EventUtil";

interface Props {
    submitButtonDisabled: boolean;
    sendPaymentRequest: () => Promise<any>;
}

interface State {
    submitButtonDisabled: boolean;
    paymentRequestSent: boolean;
}

export class PrePaymentSubmitButton extends React.Component<Props, State> {

    private eventRegistry: EventRegistry = new EventRegistry();

    constructor(p: Props) {
        super(p);
        this.eventRegistry.push({
            name: EventTypeHolder.paymentFailure,
            callback: () => {
                this.setState({
                    paymentRequestSent: false,
                    submitButtonDisabled: false
                });
            }
        });
        this.state = {
            submitButtonDisabled: p.submitButtonDisabled,
            paymentRequestSent: false
        }
    }

    sendPaymentRequest = (event: MouseEvent) => {
        event.preventDefault();
        this.disableButtonBeforePaymentRequest();
        this.props.sendPaymentRequest()
            .then(this.firePaymentRequestCompleteEvent);
        this.firePaymentRequestSentEvent();
    }

    private firePaymentRequestSentEvent = () => {
        EventUtil.fireEvent(EventTypeHolder.paymentRequestSent);
    };

    private firePaymentRequestCompleteEvent = () => {
        EventUtil.fireEvent(EventTypeHolder.paymentRequestComplete);
    };

    shouldComponentUpdate = (nextProps: Readonly<Props>,
                             nextState: Readonly<State>,
                             nextContext: any): boolean => {
        return !this.state.paymentRequestSent;
    };

    componentDidUpdate(prevProps: Readonly<Props>,
                       prevState: Readonly<State>,
                       snapshot?: any) {
        if (prevProps.submitButtonDisabled !== this.props.submitButtonDisabled) {
            this.setState({
                submitButtonDisabled: this.props.submitButtonDisabled
            })
        }
    }

    private disableButtonBeforePaymentRequest = () => {
        this.setState({
            submitButtonDisabled: true,
            paymentRequestSent: true
        });
    }


    render() {
        return (
            <>
                <div className="controls_wrapper">
                    <SubmitButton
                        type={'submit'}
                        disabled={this.disabled()}
                        onClick={this.sendPaymentRequest}>
                        Submit Payment
                    </SubmitButton>
                </div>
            </>
        );
    }

    private disabled() {
        let paymentRequestSent = this.state.paymentRequestSent;
        let submitButtonDisabled = this.state.submitButtonDisabled;
        return paymentRequestSent || submitButtonDisabled;
    }

    componentWillUnmount() {
        this.eventRegistry.clear();
    }
}