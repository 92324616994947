import React from "react";
import {container} from "tsyringe";
import {StripeService} from "../../../../../../stripe/service/StripeService";
import {CreatePaymentTokenOptions} from "../../../../../../stripe/model/CreatePaymentTokenOptions";
import {StripeCardElement} from "@stripe/stripe-js";
import {PaymentForm} from "../../../../model/PaymentForm";
import {EventTypeHolder, EventUtil} from "../../../../../../util/EventUtil";
import {PrePaymentSubmitButton} from "../../pre-payment-submit-button/PrePaymentSubmitButton";
import {StripeServiceSingleton} from "src/Context";

interface Props {
    form: React.RefObject<PaymentForm>;
    cardElement?: StripeCardElement;
    submitButtonDisabled: boolean;
    errorCallback: (message: string) => void;
}

export class PrePaymentCreditCardSubmitButton extends React.Component<Props> {

    private readonly stripeService = StripeServiceSingleton;

    private sendPaymentRequest = (): Promise<any> => {
        let options = this.getTokenOptions();
        let card = this.props.cardElement as StripeCardElement;
        return this.stripeService.confirmCardPayment(card, options)
            .then(result => {
                if (result.error && result.error.message) {
                    throw result.error.message;
                }
            })
            .catch(err => {
                console.log('paymentFailure!')
                EventUtil.fireEvent(EventTypeHolder.paymentFailure, err);
                return Promise.reject(err);
            });
    };

    private getTokenOptions = (): CreatePaymentTokenOptions => {
        let form = this.props.form.current as PaymentForm;
        return {
            name: form.nameOnCard.value,
            agreedToTerms: form.agreedToTerms.checked
        };
    }

    render() {
        return (
            <>
                <PrePaymentSubmitButton
                    sendPaymentRequest={this.sendPaymentRequest}
                    submitButtonDisabled={this.props.submitButtonDisabled}
                />
            </>
        );
    }
}